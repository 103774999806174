import { useEffect, useState } from 'react'
import { getEventService } from '../api/event'

const useEvent = (id, ticket_id) => {
    const [event, setEvent] = useState(null)
    const [ticket, setTicket] = useState(null)

    useEffect(() => {
        if (event === null) {
            getEvent(id)
        }
    }, [])

    const getEvent = async (id) => {
        const request = await getEventService(id)
        if (request.error) {
            alert('Error')
        } else {
            setEvent(request)
            setTicket(
                !!ticket_id
                    ? request.tickets.find(
                          (t) => t.id === parseInt(ticket_id, 10)
                      )
                    : request.tickets[0]
            )
        }
    }

    return { event, ticketCurrent: ticket }
}

export default useEvent
