import styled from "@emotion/styled";
import { TextField } from "@mui/material";
import React from "react";
import InputPhone from "../commons/InputPhone";
import Text from "../commons/Text";

const Buyer = (props) => {
    const { buyerForm, handleBuyer } = props;

    const handleValue = (e) => {
        handleBuyer({ [e.target.name]: e.target.value });
    };

    const handlePhone = (value, e) => {
        handleBuyer({
            country: e?.countryCode?.toUpperCase() || "AR",
            phone: value,
        });
    };

    return (
        <BuyerWrapper>
            <Text fontSize='18px' fontWeight='bold'>
                Datos del comprador
            </Text>
            <Form>
                <Text fontSize='12px' fontWeight='bold'>
                    FACTURA A NOMBRE DE
                </Text>
                <Inputs>
                    <TextField
                        onChange={handleValue}
                        value={buyerForm.name}
                        name='name'
                        size='small'
                        fullWidth
                        label='Nombre completo'
                    />
                    <TextField
                        onChange={handleValue}
                        value={buyerForm.email}
                        name='email'
                        size='small'
                        type={"email"}
                        fullWidth
                        label='E-mail'
                    />
                    <InputPhone
                        onChange={handlePhone}
                        value={buyerForm.phone}
                        placeholder='+54 XXX XXX XXX'
                        size='small'
                        fullWidth
                        variant='standard'
                        label='Teléfono'
                    />
                </Inputs>
            </Form>
        </BuyerWrapper>
    );
};

export default Buyer;

const Form = styled.div`
    width: calc(100% - 40px);
    border-radius: 20px;
    border: 1px solid #eeeeee;
    padding: 15px;
`;

const Inputs = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* padding: 0 15px; */
    padding-top: 12px;
    gap: 12px;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
`;

const BuyerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 12px 0;
`;
