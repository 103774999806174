import moment from 'moment';

export const getMonth = (month) => {
    const cases = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
    ];
    return cases[month];
};

export const getLocalUTC = () => {
    const hours = new Date().getTimezoneOffset();
    if (hours > 0) {
        return `-${hours / 60}`;
    } else {
        return `+${(hours * -1) / 60}`;
    }
};

export const getDate = (date) => {
    const utc = getLocalUTC();
    return utc >= 0 ? moment(date).subtract(utc, "hours") : moment(date).add(utc, "hours");
};
