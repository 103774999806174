import styled from '@emotion/styled'
import { Checkbox, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import InputPhone from '../commons/InputPhone'
import Text from '../commons/Text'
import ErrorIcon from '@mui/icons-material/Error'
import Span from '../commons/Span'

const Participants = (props) => {
    const { count, participants, setParticipants, buyer, buyerForm, ticket } =
        props

    const [check, setCheck] = useState(true)

    useEffect(() => {
        setInputsAmount()
    }, [count]) // eslint-disable-next-line

    const setInputsAmount = () => {
        if (count > participants.length) {
            setParticipants(
                Array(count).fill({
                    name: '',
                    email: '',
                    phone: '',
                    country: '',
                    checked: false,
                })
            )
            return
        }

        if (count === participants.length - 1) {
            setParticipants(
                participants.slice(0, participants.length - 1) || []
            )
            return
        }

        setParticipants(participants)
    }

    const handleChangeName = (e, i) => {
        const result = participants?.map((item, index) => {
            if (index === i) {
                return {
                    name: e.target.value,
                    email: item.email,
                    phone: item.phone,
                    country: item.country,
                }
            }
            return item
        })
        setParticipants(result)
    }

    const handleChangeEmail = (e, i) => {
        const result = participants?.map((item, index) => {
            if (index === i) {
                return {
                    name: item.name,
                    email: e.target.value,
                    phone: item.phone,
                    country: item.country,
                }
            }
            return item
        })
        setParticipants(result)
    }

    const handleChangePhone = (value, i, e) => {
        const result = participants?.map((item, index) => {
            if (index === i) {
                return {
                    name: item.name,
                    email: item.email,
                    phone: value,
                    country: e?.countryCode?.toUpperCase() || 'AR',
                }
            }
            return item
        })
        setParticipants(result)
    }

    const handleBuyerCheck = (e, index) => {
        const result = participants?.map((item, i) => {
            if (i === index && !!e.target.checked) {
                setCheck(i)
                return { ...buyerForm, checked: true }
            } else {
                setCheck(false)
                return { ...item, checked: false }
            }
        })
        setParticipants(result)
    }

    return (
        <ParticipantsWrapper buyer={buyer}>
            <Text fontSize="18px" fontWeight="bold">
                Completa los datos de cada entrada
            </Text>
            <Alert>
                <ErrorIcon />
                <Text fontSize="14px">
                    Estos datos serán utilizados para generar los accesos a la
                    plataforma de live streaming, procura ingresar datos reales
                </Text>
            </Alert>
            {participants?.map((item, index) => (
                <Form key={index}>
                    <Header>
                        <Text fontSize="12px" fontWeight="bold">
                            ENTRADA {index + 1}
                        </Text>
                        {!!buyer && check === true && index === 0 && (
                            <div>
                                <Span
                                    color={
                                        !buyerForm.name ||
                                        !buyerForm.email ||
                                        !buyerForm.country ||
                                        !buyerForm.phone
                                            ? '#999999'
                                            : '#b31d15'
                                    }
                                >
                                    Datos del comprador
                                </Span>
                                <Checkbox
                                    checked={item.checked}
                                    disabled={
                                        !buyerForm.name ||
                                        !buyerForm.email ||
                                        !buyerForm.country ||
                                        !buyerForm.phone
                                            ? true
                                            : false
                                    }
                                    onChange={(e) => handleBuyerCheck(e, index)}
                                    size="small"
                                    type={'checkbox'}
                                />
                            </div>
                        )}
                    </Header>
                    <Inputs>
                        <TextField
                            size="small"
                            value={item.name}
                            onChange={(e) => handleChangeName(e, index)}
                            fullWidth
                            label="Nombre completo"
                        />
                        <TextField
                            size="small"
                            value={item.email}
                            type={'email'}
                            onChange={(e) => handleChangeEmail(e, index)}
                            fullWidth
                            label="E-mail"
                        />
                        <InputPhone
                            placeholder="+54 XXX XXX XXX"
                            size="small"
                            value={item.phone}
                            onChange={(value, e) =>
                                handleChangePhone(value, index, e)
                            }
                            fullWidth
                            variant="standard"
                            label="Teléfono"
                        />
                    </Inputs>
                </Form>
            ))}
        </ParticipantsWrapper>
    )
}

export default Participants

const ParticipantsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    min-height: ${(p) => (p.buyer ? '300px' : '450px')};
    overflow-y: auto;
    @media (max-width: 1200px) {
        max-height: ${(p) => (p.buyer ? '120px' : '260px')};
    }
    @media (max-width: 1600px) {
        max-height: ${(p) => (p.buyer ? '120px' : '260px')};
    }
    @media (max-width: 768px) {
        max-height: unset;
        min-height: unset;
    }
`

const Form = styled.div`
    width: calc(100% - 40px);
    border-radius: 10px;
    border: 1px solid #eeeeee;
    padding: 15px;
`

const Inputs = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* padding: 0 15px; */
    padding-top: 0.8rem;
    gap: 1rem;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
`

const Alert = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #b31d15;
    svg {
        font-size: 20px;
    }
`

const Header = styled.div`
    display: flex;
    align-items: center;
    height: 25px;
    justify-content: space-between;
`
