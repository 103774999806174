import styled from '@emotion/styled'
import { IconButton } from '@mui/material'
import React, { useState } from 'react'
import Text from '../commons/Text'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

const Amount = (props) => {
    const { handleTickets, count, tickets, handleTypeTicket, typeTicket } =
        props

    const [active, setActive] = useState(
        parseInt(typeTicket, 10) || tickets[0]?.id || 0
    )

    const handleClick = (e, id) => {
        setActive(id)
        handleTypeTicket(tickets.find((item) => item.id === id), e)
    }

    if (!!typeTicket) {
        return (
            <Container>
                {tickets
                    .filter((item) => item.id === parseInt(typeTicket, 10))
                    .map((ticket, index) => {
                        const isGift = ticket.name
                            .toLowerCase()
                            .includes('gift')
                        return (
                            <AmountWrapper
                                key={index}
                                onClick={(e) => handleClick(e, ticket.id)}
                                active={active === ticket.id ? true : false}
                            >
                                <InfoWrapper id="clickeable">
                                    <Text
                                        fontWeight="700"
                                        fontSize="18px"
                                        color={
                                            active === ticket.id
                                                ? '#222'
                                                : '#aaa'
                                        }
                                    >
                                        {ticket.name}
                                    </Text>
                                    <Text
                                        fontSize="16px"
                                        color={
                                            active === ticket.id
                                                ? '#222'
                                                : '#aaa'
                                        }
                                    >
                                        {ticket.price === 0
                                            ? 'FREE'
                                            : `US$${ticket.price}`}
                                    </Text>
                                    <Description
                                        fontSize="12px"
                                        color="#666666"
                                    >
                                        {ticket.description}
                                    </Description>
                                </InfoWrapper>
                                {!ticket.single_ticket && (
                                    <ButtonsWrapper>
                                        {!isGift && (
                                            <IconButton
                                                disabled={
                                                    active === ticket.id
                                                        ? false
                                                        : true
                                                }
                                                onClick={(e) =>
                                                    handleTickets(-1, e)
                                                }
                                            >
                                                <RemoveIcon />
                                            </IconButton>
                                        )}
                                        <Text
                                            color={
                                                active === ticket.id
                                                    ? '#222'
                                                    : '#aaa'
                                            }
                                            fontWeight="700"
                                            fontSize="24px"
                                        >
                                            {active === ticket.id ? count : 0}
                                        </Text>
                                        {!isGift && (
                                            <IconButton
                                                disabled={
                                                    active === ticket.id
                                                        ? false
                                                        : true
                                                }
                                                onClick={(e) =>
                                                    handleTickets(1, e)
                                                }
                                            >
                                                <AddIcon />
                                            </IconButton>
                                        )}
                                    </ButtonsWrapper>
                                )}
                                <CheckCircleIconFake
                                    active={active === ticket.id ? true : false}
                                />
                            </AmountWrapper>
                        )
                    })}
            </Container>
        )
    }

    return (
        <Container>
            {tickets.slice(0, 2).map((ticket, index) => {
                const isGift = ticket.name.toLowerCase().includes('gift')
                return (
                    <AmountWrapper
                        key={index}
                        onClick={(e) => handleClick(e, ticket.id)}
                        active={active === ticket.id ? true : false}
                    >
                        <InfoWrapper id="clickeable">
                            <Text
                                fontWeight="700"
                                fontSize="18px"
                                color={active === ticket.id ? '#222' : '#aaa'}
                            >
                                {ticket.name}
                            </Text>
                            <Text
                                fontSize="16px"
                                color={active === ticket.id ? '#222' : '#aaa'}
                            >
                                {ticket.price === 0
                                    ? 'FREE'
                                    : `US$${ticket.price}`}
                            </Text>
                            <Description fontSize="12px" color="#666666">
                                {ticket.description}
                            </Description>
                        </InfoWrapper>
                        {!ticket.single_ticket && (
                            <ButtonsWrapper>
                                {!isGift && (
                                    <IconButton
                                        disabled={
                                            active === ticket.id ? false : true
                                        }
                                        onClick={(e) => handleTickets(-1, e)}
                                    >
                                        <RemoveIcon />
                                    </IconButton>
                                )}
                                <Text
                                    color={
                                        active === ticket.id ? '#222' : '#aaa'
                                    }
                                    fontWeight="700"
                                    fontSize="24px"
                                >
                                    {active === ticket.id ? count : 0}
                                </Text>
                                {!isGift && (
                                    <IconButton
                                        disabled={
                                            active === ticket.id ? false : true
                                        }
                                        onClick={(e) => handleTickets(1, e)}
                                    >
                                        <AddIcon />
                                    </IconButton>
                                )}
                            </ButtonsWrapper>
                        )}
                        <CheckCircleIconFake
                            active={active === ticket.id ? true : false}
                        />
                    </AmountWrapper>
                )
            })}
        </Container>
    )
}

export default Amount

const AmountWrapper = styled.div`
    height: 160px;
    width: calc(100% - 36px);
    position: relative;
    border-radius: 20px;
    margin: 25px 0;
    /* background-color: red; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 18px;
    cursor: pointer;
    transition: ease-in-out 0.3s all;
    ${(p) => p.active && `background-color: #f7fdfb`};
    ${(p) =>
        !p.active &&
        `:hover{
        box-shadow: 1px 0px 10px 0px rgba(143,134,134,0.75);
    }`};
    border: ${(p) => p.border};
    box-shadow: ${(p) =>
        !p.border &&
        '0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08)'};
    @media (max-width: 768px) {
        padding: 12px;
        margin: 5px 0;
    }
`

const ButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
`

const InfoWrapper = styled.div`
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    gap: 6px;
`

const Container = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding: 20px 0;
    }
`

const CheckCircleIconFake = styled(CheckCircleIcon)`
    position: absolute;
    right: 10px;
    top: 10px;
    color: ${(p) => (p.active ? '#3bd39c' : '#aaaaaa')};
    font-size: 18px;
`

const Description = styled(Text)`
    max-height: 60px;
    overflow-y: auto;
    margin-bottom: 15px;
`
