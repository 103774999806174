import React from 'react'
import { useParams } from 'react-router-dom';
import * as base64  from "base-64";
import styled from '@emotion/styled';
import Text from '../components/commons/Text';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const Activator = () => {

    const { id } = useParams();
    const users = JSON.parse(base64.decode(id))

    console.log('id', users)
    const url = 'https://app.aden.org/auth/signup/'

    return (
        <TicketWrapper>
            <Text id="title" fontSize="24px" fontWeight="700" color="#b31d15">Activador de cuentas</Text>
            <List>
                {users.map(user => (<Item onClick={() => window.open(url + base64.encode(user.partner_id))}><span>Usuario: {user.email}</span><ArrowForwardIcon/> </Item>))}
            </List>
        </TicketWrapper>
    )
}

export default Activator

const TicketWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    #title{
        margin: 18px 0;
    }
    @media(max-width: 768px){
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
`

const List = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`

const Item = styled.div`
    border: 1px solid #b31d15;
    background-color: #b31d1511;
    padding: 15px 23px;
    width: 450px;
    border-radius: 20px;
    color: #b31d15;
    font-weight: bold;
    display: flex;
    font-size: 18px;
    justify-content: space-between;
    cursor: pointer;
    transition: all ease-in-out .3s;
    :hover{
        box-shadow: 1px 2px 20px -10px rgba(128,128,128,0.75);
    }
`