import React from 'react'
// import ReactPhoneInput from 'react-phone-input-material-ui';
import styled from '@emotion/styled'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const InputPhone = (props) => {
    const masks = {
        ar: '(...) ...-....',
    }

    return (
        <ReactPhoneInputFake
            // component={TextField}
            masks={masks}
            enableAreaCodes={true}
            country={'ar'}
            // onlyCountries={['ar']}
            inputProps={{
                name: 'phone',
                required: true,
            }}
            // defaultCountry={'AR'}
            {...props}
        />
    )
}

export default InputPhone

const ReactPhoneInputFake = styled(PhoneInput)`
    /* z-index: 99; */
    .form-control {
        height: 40px;
        width: 100%;
    }
    .country-list {
        z-index: 99;
    }
    /* .MuiInputLabel-root{
        top: -8px;
    }
    .Mui-focused{
        top: 0px;
    }
    input{
        padding: 8.5px 5px;
        padding-left: 12px;
    } */
`
