import styled from '@emotion/styled'
import React from 'react'

const Text = (props) => {

    return (
        <TextWrapper {...props}></TextWrapper>
    )
}

export default Text

const TextWrapper = styled.div`
    font-size: ${p => p.fontSize};
    font-weight: ${p => p.fontWeight};
    color: ${p => p.color};
    text-align: ${p => p.textAlign};
    font-family: 'Lato', sans-serif !important;
    margin: ${p => p.margin};
    span {
        font-family: 'Lato', sans-serif !important;
    }
`