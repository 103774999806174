import axios from 'axios'

const URL = process.env.REACT_APP_SISAPI

export const getEventService = async (id) => {
    try {
        const req = await axios.get(`${URL}/v2/acropolis/events/${id}`)
        return req.data.response_data
    } catch (error) {
        return { error }
    }
}

export const registerEvent = async (body) => {
    try {
        const req = await axios.post(
            `${URL}/v2/acropolis/events/register-free`,
            body
        )
        return req.data?.response_data
    } catch (error) {
        return { error }
    }
}

export const registerEventPremium = async (body) => {
    try {
        const req = await axios.post(
            `${URL}/v2/acropolis/events/register-premium`,
            body
        )
        return req.data?.response_data
    } catch (firstError) {
        try {
            // const request = await fetch(
            //     'https://api.acropolis.org.mx/v2/acropolis/events/register-premium',
            //     {
            //         method: 'POST',
            //         headers: {
            //             'Content-Type': 'application/json',
            //         },
            //         body: body,
            //     }
            // )
            const req = await axios.post(
                `${URL}/v2/acropolis/events/register-premium`,
                body
            )
            return req.data?.response_data
        } catch (secondError) {
            return { secondError }
        }
    }
}

export const checkCode = async (body) => {
    try {
        const req = await axios.post(
            `${URL}/v2/acropolis/events/validate_code`,
            body
        )
        return req.data
    } catch (error) {
        return { error }
    }
}
