import styled from '@emotion/styled'
import React, { useState } from 'react'
import Text from '../commons/Text'
import NavBar from './NavBar'
import WhatsappChat from '../commons/WhatsappChat'

const Layout = (props) => {
    const { component: Component } = props

    const [menu, setMenu] = useState(false)

    const handleTogleMain = (state) => {
        setMenu(state)
    }

    return (
        <>
            <NavBar isOpen={menu} handleTogleMain={handleTogleMain} />
            <WhatsappChat />
            <LayoutContainer>
                <Component isOpen={menu} handleTogleMain={handleTogleMain} />
            </LayoutContainer>
            <Terms>
                <div>
                    <Text>
                        Creado y desarrollado por © ADEN{' '}
                        {new Date().getFullYear()}
                    </Text>
                    <Link
                        onClick={() =>
                            window.open('https://www.aden.org/privacy-policy/')
                        }
                        color="#b31d15"
                    >
                        Políticas de privacidad
                    </Link>
                </div>
            </Terms>
        </>
    )
}

export default Layout

const LayoutContainer = styled.div`
    width: calc(100% - 480px);
    min-height: calc(100vh - 160px);
    max-height: 100%;
    margin: 40px 240px;
    background-color: white;
    border-radius: 20px;
    box-shadow:
        rgba(24, 39, 75, 0.12) 0px 6px 12px -6px,
        rgba(24, 39, 75, 0.08) 0px 8px 24px -4px;
    // Small devices (landscape phones, 576px and up)
    /* @media (min-width: 576px) { ... } */

    // Medium devices (tablets, 768px and up)

    // Large devices (desktops, 992px and up)
    /* @media (min-width: 992px) { ... } */

    // X-Large devices (large desktops, 1200px and up)
    @media (max-width: 1200px) {
        width: calc(100% - 180px);
        margin: 20px 90px;
    }
    @media (max-width: 1600px) {
        width: calc(100% - 160px);
        margin: 20px 80px;
    }
    @media (max-width: 768px) {
        width: 100%;
        margin: 0;
        border-radius: 0px;
        height: auto;
        max-height: unset;
    }
`

const Terms = styled.div`
    display: flex;
    justify-content: center;
    div {
        text-align: center;
    }

    @media (max-width: 768px) {
        display: none;
    }
`

const Link = styled(Text)`
    cursor: pointer;
    margin: 10px 0;
`
