
export default function setupAxios(axios, token) {
    axios.interceptors.request.use(
        (config) => {
            if (!config.headers.Authorization) {
                config.headers.Authorization = token;
            }
            return config;
        },
        (err) => Promise.reject(err)
    );
}
