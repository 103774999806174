import { BubbleChat } from 'flowise-embed-react'
import './App.css'
import Layout from './components/layout/Layout'
import TicketPage from './pages/TicketPage'
import { Routes, Route, Link } from 'react-router-dom'
import Activator from './pages/Activator'

function App() {
    const chatflowid = process.env.REACT_APP_CHATFLOW_ID
    const apiHost = process.env.REACT_APP_CHATFLOW_API_HOST

    const chatbotTheme = {
        button: {
            backgroundColor: '#3B81F6',
            right: 20,
            bottom: 20,
            size: 'medium',
            iconColor: 'white',
            customIconSrc:
                'https://raw.githubusercontent.com/walkxcode/dashboard-icons/main/svg/google-messages.svg',
        },
        chatWindow: {
            welcomeMessage: '¡Hola! ¿En qué puedo ayudarte hoy?',
            backgroundColor: '#ffffff',
            height: 700,
            width: 400,
            fontSize: 16,
            poweredByTextColor: '#303235',
            botMessage: {
                backgroundColor: '#f7f8ff',
                textColor: '#303235',
                showAvatar: true,
                avatarSrc:
                    'https://raw.githubusercontent.com/sebamaidana/documentos/main/ADEN_flowise_icon1.png',
            },
            userMessage: {
                backgroundColor: '#3B81F6',
                textColor: '#ffffff',
                showAvatar: true,
                avatarSrc:
                    'https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/usericon.png',
            },
            textInput: {
                placeholder: 'Escribe tu consulta ...',
                backgroundColor: '#ffffff',
                textColor: '#303235',
                sendButtonColor: '#3B81F6',
            },
        },
    }

    return (
        <>
            {/* <BubbleChat
                chatflowid={chatflowid}
                apiHost={apiHost}
                theme={chatbotTheme}
            /> */}
            <Routes>
                <Route path="/" element={<Layout component={TicketPage} />} />
                <Route
                    path="activador/:id"
                    element={<Layout component={Activator} />}
                />
            </Routes>
        </>
    )
}

export default App
