import styled from '@emotion/styled'
import React, { useEffect } from 'react'
import Loading from '../commons/Loading'
import Text from '../commons/Text'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber'
import Span from '../commons/Span'
import { Button, Menu, MenuItem } from '@mui/material'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import { HistoryRounded } from '@mui/icons-material'
import { responseTuCuota } from '../../helpers/tucuota'
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo'
import { google, outlook, office365, yahoo, ics } from 'calendar-link'
import moment from 'moment'
import { getDate, getLocalUTC } from '../../helpers/date'

const Process = (props) => {
    const {
        count,
        confirm,
        ticket,
        event,
        participants,
        isPay,
        error = null,
        hash,
    } = props

    const utc = getLocalUTC()

    useEffect(() => {
        if (confirm === 'confirm') {
            localStorage.removeItem("error")
            const path = window.location.search
            if (!path.includes('status=ok')) {
                window.history.pushState(
                    null,
                    '',
                    path + (path.includes('?') ? '&status=ok' : '?status=ok')
                )
            }
        } else if (confirm === 'error') {
            localStorage.setItem('error', JSON.stringify(error?.error?.response))
            window.location.reload()
            /* console.log('@TODO - Remover query params') */
        } else {
            localStorage.removeItem("error")
        }
    }, [confirm])

    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const linkPay =
        isPay?.payment_link ||
        'http://localhost:3000/suscripcion/377b1ec81d4c11ec9baf0242c0a83004'

    const string = '-'
    const regex = new RegExp(string, 'g')
    const dateInit = event.date_begin.split(' ')[0].replace(regex, '')
    const dateFinish = event.date_end.split(' ')[0].replace(regex, '')
    let participantsGoogle = participants.map((item) => item.email)
    /* participants.forEach((item) => (participantsGoogle += `add=${item.email}&`)); */

    const calendarEvent = {
        title: event.publication_name,
        description: event.description,
        location: 'Mendoza, Argentina',
        guests: participantsGoogle,
        start: getDate(event.date_begin).local().format(),
        end: getDate(event.date_end).local().format(),
    }

    const urlGoogle = `https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${getDate(
        event.date_begin
    )
        .local()
        .format()}%2F${getDate(event.date_end)
        .local()
        .format()}&details=${event.description}&location=Mendoza%2C%20Argentina&text=${
        event.publication_name
    }&${participantsGoogle}`
    const urlOutlook = `https://outlook.live.com/calendar/0/deeplink/compose?body=${event.description}&enddt=${getDate(
        event.date_end
    )
        .local()
        .format()}&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=${getDate(
        event.date_begin
    )
        .local()
        .format()}&subject=${event.publication_name}`

    return (
        <Wrapper>
            {/* Pantalla de carga */}
            {confirm === 'loading' && (
                <Body>
                    <Loading />
                    <Text color="#B31D15" fontSize="14px" fontWeight="700">
                        Reservando tus tickets...
                    </Text>
                </Body>
            )}
            {/* Pantalla error */}
            {confirm === 'error' && (
                <>
                    {/* <Loading /> */}
                    <Body>
                        <Cicle bg={'#B31D15'}>
                            <CloseIcon />
                        </Cicle>
                        <Text color="#222222" fontWeight="900" fontSize="34px">
                            ¡Ocurrió un error al procesar el pago!
                        </Text>
                        <Text fontSize="16px" fontWeight="600">
                            ERROR:{' '}
                            <Span color={'#B31D15'} fontWeight="600">
                                {responseTuCuota(error?.error?.response)}
                            </Span>
                        </Text>
                        <CountTicket>
                            <Span color="#999999">{count}x</Span>
                            <Span fontWeight="500">Ticket {ticket?.name}</Span>
                        </CountTicket>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => window.location.reload()}
                        >
                            Reintentar
                        </Button>
                    </Body>
                </>
            )}
            {/* Pantalla FREEMIUM */}
            {confirm === 'confirm' && !isPay && (
                <Body>
                    <Cicle bg={'#35D0A5'}>
                        <CheckIcon />
                    </Cicle>
                    <Text color="#222222" fontWeight="900" fontSize="34px">
                        ¡Tickets confimados!
                    </Text>
                    <CountTicket>
                        <Span color="#999999">{count}x</Span>
                        <Span fontWeight="500">Ticket {ticket?.name}</Span>
                    </CountTicket>
                    <Text
                        textAlign="center"
                        color="#A8A8A8"
                        fontWeight="400"
                        fontSize="18px"
                    >
                        Te mantendremos informado de todas las novedades vía
                        mail.
                    </Text>
                    <Message>
                        <ConfirmationNumberIcon />
                        <Text color="#616161" fontWeight="400" fontSize="14px">
                            {' '}
                            Los tickets adquiridos no son reembolsables
                        </Text>
                    </Message>
                    <Button
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        startIcon={<CalendarMonthIcon />}
                    >
                        Añadir al calendario
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem
                            onClick={() => window.open(google(calendarEvent))}
                        >
                            Google Calendar
                        </MenuItem>
                        <MenuItem
                            onClick={() => window.open(outlook(calendarEvent))}
                        >
                            Outlook
                        </MenuItem>
                        <MenuItem
                            onClick={() =>
                                window.open(office365(calendarEvent))
                            }
                        >
                            Office 365
                        </MenuItem>
                        <MenuItem
                            onClick={() => window.open(yahoo(calendarEvent))}
                        >
                            Yahoo
                        </MenuItem>
                        <MenuItem
                            onClick={() => window.open(ics(calendarEvent))}
                        >
                            ICalendar
                        </MenuItem>
                    </Menu>
                    {ticket?.id === 24 && (
                        <LiveLink
                            onClick={() =>
                                window.open(
                                    'https://live.aden.org/dispatch_ticket/24'
                                )
                            }
                        >
                            <OndemandVideoIcon />
                            <span>Ir al streaming</span>
                        </LiveLink>
                    )}
                    <Activator
                        onClick={() =>
                            window.open(
                                window.location.origin + '/activador/' + hash
                            )
                        }
                    >
                        Activar cuentas
                    </Activator>
                </Body>
            )}
            {/* Pantalla PREMIUM */}
            {confirm === 'confirm' && isPay && (
                <Body>
                    <Cicle bg={'#35D0A5'}>
                        <HistoryRounded />
                    </Cicle>
                    <TextWrapper>
                        <Text color="#222222" fontWeight="900" fontSize="34px">
                            Tu pago está siendo preocesado
                        </Text>
                        <Text color="#222222" fontWeight="400" fontSize="14px">
                            Cuando terminemos de procesarlote enviaremos la confirmación del pago y la factura a tu correo.
                        </Text>
                    </TextWrapper>
                    <CountTicket>
                        <Span color="#999999">{count}x</Span>
                        <Span fontWeight="500">Ticket {ticket?.name}</Span>
                    </CountTicket>
                    <Text
                        textAlign="center"
                        color="#A8A8A8"
                        fontWeight="400"
                        fontSize="18px"
                    >
                        Te mantendremos informado de todas las novedades vía
                        mail.
                    </Text>
                    <Text fontWeight="600" fontSize="16px">
                        PARA VER EL ESTADO DEL PAGO, PRESIONA EL SIGUIENTE BOTÓN
                    </Text>
                    <LinkPay
                        onClick={() => window.open(linkPay)}
                        title="Ir al pago"
                    >
                        <Span fontWeight="600" color="white">
                            Ver el pago:{' '}
                        </Span>
                        <Elipsis>{linkPay}</Elipsis>
                    </LinkPay>
                    <Message>
                        <ConfirmationNumberIcon />
                        <Text color="#616161" fontWeight="400" fontSize="14px">
                            {' '}
                            Los tickets adquiridos no son reembolsables
                        </Text>
                    </Message>
                    <Button
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        startIcon={<CalendarMonthIcon />}
                    >
                        Añandir al calendario
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={() => window.open(urlGoogle)}>
                            Google Calendar
                        </MenuItem>
                        <MenuItem onClick={() => window.open(urlOutlook)}>
                            Outlook
                        </MenuItem>
                        <MenuItem
                            onClick={() =>
                                window.open(office365(calendarEvent))
                            }
                        >
                            Office 365
                        </MenuItem>
                        <MenuItem
                            onClick={() => window.open(yahoo(calendarEvent))}
                        >
                            Yahoo
                        </MenuItem>
                        <MenuItem
                            onClick={() => window.open(ics(calendarEvent))}
                        >
                            ICalendar
                        </MenuItem>
                    </Menu>
                    {ticket?.id === 24 && (
                        <LiveLink
                            onClick={() =>
                                window.open(
                                    'https://live.aden.org/dispatch_ticket/24'
                                )
                            }
                        >
                            <OndemandVideoIcon />
                            <span>Ir al streaming</span>
                        </LiveLink>
                    )}
                    <Activator
                        onClick={() =>
                            window.open(
                                window.location.origin + '/activador/' + hash
                            )
                        }
                    >
                        Activar cuentas
                    </Activator>
                </Body>
            )}
        </Wrapper>
    )
}

export default Process

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

const Cicle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 78px;
    height: 78px;
    border-radius: 50%;
    background-color: ${(p) => p.bg};
    color: white;
    svg {
        font-size: 48px;
    }
`

const Body = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 2rem;
`

const Message = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 12px;
    align-items: center;
    svg {
        color: #a8a8a8;
    }
`

const CountTicket = styled.div`
    width: 80%;
    background: #ffffff;
    border: 1px solid #f1f1f1;
    height: 38px;
    box-sizing: border-box;
    box-shadow:
        0px 4px 6px -4px rgba(24, 39, 75, 0.12),
        0px 8px 8px -4px rgba(24, 39, 75, 0.08);
    border-radius: 20px;
    display: flex;
    align-items: center;
    padding: 0 15px;
    gap: 10px;
`

const LinkPay = styled.div`
    width: 500px;
    /* min-width: 100%; */
    max-width: 500px;
    background: #b31d15;
    /* border: 1px solid #b31d15; */
    height: 45px;
    box-sizing: border-box;
    box-shadow:
        0px 4px 6px -4px rgba(24, 39, 75, 0.12),
        0px 8px 8px -4px rgba(24, 39, 75, 0.08);
    border-radius: 20px;
    display: flex;
    align-items: center;
    padding: 0 15px;
    gap: 10px;
    transition: ease-in-out all 0.3s;
    cursor: pointer;
    color: white;
    :hover {
        box-shadow:
            0px 4px 6px -4px rgba(179, 29, 21, 0.4),
            0px 8px 8px -4px rgba(179, 29, 21, 0.3);
    }
`

const Elipsis = styled.span`
    width: 370px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`

const LiveLink = styled.div`
    border-radius: 20px;
    cursor: pointer;
    border: 1.3px solid #b31d15;
    text-transform: uppercase;
    padding: 12px 36px;
    font-weight: bold;
    background-color: #b31d1511;
    color: #b31d15;
    display: flex;
    gap: 10px;
    align-items: center;
    transition: all ease-in-out 0.3s;
    :hover {
        filter: opacity(0.8);
    }
`

const Activator = styled.div`
    border-radius: 20px;
    border: 1px solid #35d0a5;
    padding: 10px 18px;
    width: 160px;
    display: flex;
    justify-content: center;
    color: #35d0a5;
    cursor: pointer;
    background-color: #35d0a511;
    font-weight: 600;
`

const TextWrapper = styled.div`
    display: grid;
    place-items: center;
    row-gap: 0.5rem;
`
