import React from 'react'
import styled from 'styled-components'

import { WhatsApp } from '@mui/icons-material'
import { IconButton } from '@mui/material'

const WhatsappChat = () => {
    const URL = `https://api.whatsapp.com/send/?phone=5492617504810`

    const handleRedirect = () => {
        window.open(URL)
    }

    return (
        <></>
        // <WhatsappIconWrapper onClick={handleRedirect}>
        //     <WhatsApp />
        // </WhatsappIconWrapper>
    )
}

export default WhatsappChat

const WhatsappIconWrapper = styled(IconButton)`
    position: fixed !important;
    width: 50px;
    height: 50px;
    background-color: #07a004 !important;
    right: 4.5rem;
    bottom: 3rem;
    z-index: 15;

    svg {
        fill: #ffffff;
        width: 32px;
        height: 32px;
    }

    &:hover {
        background-color: #0bb205;
    }
`
