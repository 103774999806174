import { useEffect, useState } from 'react'

const useValidateParticipants = (participants) => {
    const [values, setValues] = useState(false)

    useEffect(() => {
        validateParticipants(participants)
    }, [participants])

    const validateParticipants = (array) => {
        if (participants.length > 0) {
            const result = array.filter(
                (item) =>
                    item.name ||
                    item.email ||
                    item.phone ||
                    validateEmail(item.email)
            )
            result.length > 0 && setValues(true)
            result.length === 0 && setValues(false)
        }
    }

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
    }

    return { state: values }
}

export default useValidateParticipants
