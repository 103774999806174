import styled from '@emotion/styled'
import { CircularProgress } from '@mui/material'
import React from 'react'

const Loading = () => {
    return (
        <Wrapper>
            <CircularProgress />
        </Wrapper>
    )
}

export default Loading

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
`