import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
// import { useParams } from 'react-router'
import { registerEvent, registerEventPremium } from '../api/event'
import { createLog } from '../api/history'
import EventCard from '../components/ui/EventCard'
import Form from '../components/ui/Form'
import Pay from '../components/ui/Pay'
import Process from '../components/ui/Process'
import useEvent from '../hooks/useEvent'
import useQueryParams from '../hooks/useQueryParams'
import * as base64 from 'base-64'
import { ErrorOutline } from '@mui/icons-material'

const EVENT_ID = process.env.REACT_APP_EVENTID || 2308

const TicketPage = (props) => {
    const { isOpen, handleTogleMain } = props

    const ONE_TICKET_CASES = ['55', '61']

    const {
        utm_campaign = '',
        utm_source = 'Indefinido',
        utm_medium = 'Indefinido',
        type_ticket = false,
        discount_code = '',
    } = useQueryParams()

    const { event, ticketCurrent } = useEvent(EVENT_ID, type_ticket)
    // Cantidad de entradas
    const [count, setCount] = useState(
        ONE_TICKET_CASES.includes(type_ticket) ? 1 : 5
    )
    // Participantes
    const [participants, setParticipants] = useState([])
    // Confirmacion de registro
    const [confirm, setConfirm] = useState(false)
    // Ticket
    const [ticket, setTicket] = useState(null)
    // Datos de facturacion
    const [buyerForm, setBuyer] = useState({})
    // Fue pagado
    const [isPay, setIsPay] = useState(false)
    // Error TuCuota
    const [error, setError] = useState(null)
    // Código de descuento
    const [code, setCode] = useState(null)
    // Participantes
    const [users, setUsers] = useState(null)

    useEffect(() => {
        !ticket && !!ticketCurrent && setTicket(ticketCurrent)
        ticket && getPage()
    }, [ticketCurrent])

    const getPage = () => {
        let store = localStorage.getItem('pending_pay')
        let defBuyer = localStorage.getItem('buyer_form')
        let participants = localStorage.getItem('participants')
        if (!!store) {
            store = JSON.parse(store)
            setTicket(store.ticket)
            setCount(store.count)
            setIsPay(store.hash)
            setBuyer(store.buyer)
            setCode(store.code)
            setConfirm('pay')
        }

        if (!!defBuyer) {
            setBuyer(JSON.parse(defBuyer))
        }

        if (!!participants) {
            setParticipants(JSON.parse(participants))
        }
    }

    const handleTickets = (value, e) => {
        e.stopPropagation()
        e.cancelBubble = true
        if (count + value <= 1) {
            setCount(1)
            return
        }
        if (count + value >= 5) {
            setCount(5)
            return
        }
        setCount(count + value)
    }

    const handleSubmit = async () => {
        setConfirm('loading')
        const body = {
            event_id: event.crm_id,
            buyer: ticket?.price > 0 ? buyerForm : {},
            ticket: {
                id: ticket.id,
            },
            quantity: 1,
            // quantity: count,
            participants: participants.filter(
                (participant) =>
                    participant.name || participant.email || participant.phone
            ),
            utm: {
                utm_campaign,
                utm_source,
                utm_medium,
            },
            discount_code: code?.code || false,
        }
        const log_body = {
            name: buyerForm.name,
            dni: 'false',
            phone: buyerForm.phone,
            email: buyerForm.email,
            product: 1,
            url: window.location.href,
            discount_code: code?.code,
            action: `ticketcumbre - datos usuario - Tipo de ticket: ${ticket?.price > 0 ? 'Premium' : 'Free'} - Participantes: ${JSON.stringify(participants)} - Datos comprador: ${JSON.stringify(buyerForm)}`,
        }

        createLog(log_body)

        // const request = true
        const request =
            (await ticket?.price) > 0
                ? await registerEventPremium(body)
                : await registerEvent(body)
        if (request.error) {
            setConfirm('error')
            const log_body = {
                product: 1,
                url: window.location.href,
                discount_code: code?.code,
                action: `ticketcumbre - selección de entradas - respuesta de la API: Error - tipo de ticket: ${ticket?.price > 0 ? 'Premium' : 'Free'} - id del ticket: ${ticket.id} - participantes: ${participants} - Error: ${request.error}`,
            }

            createLog(log_body)
        } else {
            setConfirm(
                ticket?.price > 0 && request.payment_link ? 'pay' : 'confirm'
            )
            setIsPay(
                ticket?.price > 0 && request.payment_link ? request : false
            )

            const participants = JSON.stringify(request?.participants)
            const hash = base64.encode(participants)
            setUsers(hash)

            const log_body = {
                name: buyerForm.name,
                dni: 'false',
                phone: buyerForm.phone,
                email: buyerForm.email,
                product: 1,
                url: window.location.href,
                discount_code: code?.code,
                action: `ticketcumbre - selección de entradas - respuesta de la API: Success - tipo de ticket: ${ticket?.price > 0 ? 'Premium' : 'Free'} - id del ticket: ${ticket.id} - participantes: ${participants} - estado del pago: ${isPay} - confirmación: ${confirm}`,
            }

            createLog(log_body)
        }
    }

    const handleTypeTicket = (ticket, e) => {
        e.stopPropagation()
        e.cancelBubble = true

        setTicket(ticket)
        if (ticket.single_ticket) {
            setCount(1)
            setParticipants(Array(1).fill({
                name: '',
                email: '',
                phone: '',
                country: '',
                checked: false,
            }))
            return
        }
        setCount(5)
    }

    const handleBuyer = (item) => {
        setBuyer({ ...buyerForm, ...item })
    }

    const handleStatePay = (state) => {
        setConfirm(state?.code)
        setError(state?.request)
    }

    const handleBack = (prevState) => {
        setConfirm(prevState)
        localStorage.removeItem('pending_pay')
    }

    const handleCode = (code) => {
        setCode(code)
    }

    if (!event) {
        return <></>
    }

    // Pagina para pagar entrada premium
    if (confirm === 'pay') {
        return (
            <TicketWrapper grid="2fr 1fr">
                <Pay
                    code={code}
                    ticket={ticket}
                    count={count}
                    hash={isPay}
                    buyer={buyerForm}
                    handleStatePay={handleStatePay}
                    handleBack={handleBack}
                />
                <EventCard
                    defaultCode={discount_code}
                    code={code}
                    eventId={EVENT_ID}
                    isOpen={isOpen}
                    handleTogleMain={handleTogleMain}
                    ticket={ticket}
                    event={event}
                    confirmBtn={false}
                    confirm={false}
                    participants={participants}
                    count={count}
                />
            </TicketWrapper>
        )
    }

    // Pagina de confirmacion de entrada free
    if (confirm === 'finish') {
        return (
            <TicketWrapper grid="1fr 2fr">
                <EventCard
                    defaultCode={discount_code}
                    code={code}
                    isOpen={isOpen}
                    handleTogleMain={handleTogleMain}
                    event={event}
                    confirm={true}
                    participants={participants}
                    count={count}
                />
                <Process
                    hash={users}
                    eventId={EVENT_ID}
                    isPay={isPay}
                    event={event}
                    ticket={ticket}
                    confirm={confirm}
                    participants={participants}
                    count={count}
                />
            </TicketWrapper>
        )
    }

    // Página de carga
    if (confirm === 'loading') {
        return (
            <TicketWrapper grid="2fr 1fr">
                <Process
                    hash={users}
                    isPay={isPay}
                    event={event}
                    ticket={ticket}
                    confirm={confirm}
                    participants={participants}
                    count={count}
                />
                <EventCard
                    defaultCode={discount_code}
                    code={code}
                    eventId={EVENT_ID}
                    isOpen={isOpen}
                    handleTogleMain={handleTogleMain}
                    event={event}
                    confirm={true}
                    participants={participants}
                    count={count}
                />
            </TicketWrapper>
        )
    }

    // Estados del pago
    if (confirm === 'confirm') {
        return (
            <TicketWrapper grid="1fr 2fr">
                <EventCard
                    defaultCode={discount_code}
                    code={code}
                    eventId={EVENT_ID}
                    isOpen={isOpen}
                    handleTogleMain={handleTogleMain}
                    event={event}
                    confirm={true}
                    participants={participants}
                    count={count}
                />
                <Process
                    hash={users}
                    isPay={isPay}
                    event={event}
                    ticket={ticket}
                    confirm={confirm}
                    participants={participants}
                    count={count}
                />
            </TicketWrapper>
        )
    }

    if (confirm === 'error') {
        return (
            <TicketWrapper grid="1fr 2fr">
                <EventCard
                    defaultCode={discount_code}
                    code={code}
                    eventId={EVENT_ID}
                    isOpen={isOpen}
                    handleTogleMain={handleTogleMain}
                    event={event}
                    confirm={true}
                    participants={participants}
                    count={count}
                />
                <Process
                    hash={users}
                    error={error}
                    isPay={isPay}
                    event={event}
                    ticket={ticket}
                    confirm={confirm}
                    participants={participants}
                    count={count}
                />
            </TicketWrapper>
        )
    }

    return (
        <TicketWrapper grid="2fr 1fr">
            <Form
                typeTicket={type_ticket}
                handleBuyer={handleBuyer}
                buyerForm={buyerForm}
                buyer={ticket?.price > 0 ? true : false}
                handleTogleMain={handleTogleMain}
                tickets={event?.tickets || []}
                handleTypeTicket={handleTypeTicket}
                count={count}
                participants={participants}
                setParticipants={setParticipants}
                handleTickets={handleTickets}
                ticket={ticket}
            />
            <EventCard
                defaultCode={discount_code}
                code={code}
                handleCode={handleCode}
                eventId={EVENT_ID}
                isBuyer={ticket?.price > 0 ? true : false}
                buyer={buyerForm}
                ticket={ticket}
                event={event}
                handleSubmit={handleSubmit}
                isOpen={isOpen}
                handleTogleMain={handleTogleMain}
                participants={participants}
                count={count}
            />
        </TicketWrapper>
    )
}

export default TicketPage

const TicketWrapper = styled.div`
    display: grid;
    grid-template-columns: ${(p) => p.grid};
    height: 100%;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
`
