import React, { useEffect, useState } from 'react'
import {
    formatCVC,
    formatExpirationDate,
    formatCreditCardNumber,
} from '../../utils/utils'
import Card from 'react-credit-cards'
import 'react-credit-cards/es/styles-compiled.css'
import { Button, IconButton, TextField } from '@mui/material'
import styled from '@emotion/styled'
import Text from '../commons/Text'
import { processPay } from '../../api/checkout'
import { createLog } from '../../api/history'
import ErrorIcon from '@mui/icons-material/Error'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import Loading from '../commons/Loading'
import ErrorCard from './ErrorCard'

const Pay = (props) => {
    const { buyer, hash, ticket, count, handleStatePay, code, handleBack } =
        props

    const initialState = {
        number: '',
        name: buyer?.name || '',
        expiry: '',
        cvc: '',
        issuer: '',
        focused: '',
        formData: null,
    }
    const [state, setState] = useState(initialState)
    const [expireV, setExpireV] = useState(false)
    const [loading, setLoading] = useState(false)

    let error = localStorage.getItem('error')

    useEffect(() => {
        localStorage.setItem(
            'pending_pay',
            JSON.stringify({ hash, buyer, ticket, count, code })
        )
    }, [])

    const handleCallback = ({ issuer }, isValid) => {
        setState({ ...state, issuer })
    }

    const handleInputFocus = ({ target }) => {
        setState({
            ...state,
            focused: target.name,
        })
    }

    const validateDate = (date) => {
        const dateSplit = date.split('/')
        const month = parseInt(dateSplit[0], 10)
        const year = parseInt('20' + dateSplit[1], 10)
        const getYear = parseInt(new Date().getFullYear(), 10)
        const getMonth = parseInt(new Date().getMonth() + 1, 10)

        if (month > 12 || month <= 0) {
            setExpireV('El mes tiene que ser mayor a 1 y menor a 12')
        } else if (year === getYear && month <= getMonth) {
            setExpireV('No puede ser menor a la fecha actual')
        } else {
            setExpireV(false)
        }
    }

    const handleInputChange = ({ target }) => {
        if (target.name === 'number') {
            target.value = formatCreditCardNumber(target.value)
        } else if (target.name === 'expiry') {
            target.value = formatExpirationDate(target.value)
            validateDate(target.value)
        } else if (target.name === 'cvc') {
            target.value = formatCVC(target.value)
        }

        setState({ ...state, [target.name]: target.value })
    }

    const handleSubmit = async () => {
        setLoading(true)
        const date = state.expiry.split('/')
        const body = {
            hash: hash?.payment_link?.split('pago/')[1],
            card: state.number.replace(/ /g, ''),
            expiration_month: date[0],
            expiration_year: '20' + date[1],
            user: {
                name: state.name,
                email: buyer?.email || null,
                phone: buyer?.phone || null,
                birth_date: null,
                dni: null,
            },
        }
        const request = await processPay(body)

        localStorage.removeItem("error");

        const log_body = {
            name: state.name,
            dni: 'false',
            phone: state.phone,
            email: state.email,
            product: 1,
            url: window.location.href,
            action: `ticketcumbre - proceso de pago - respuesta de la API: ${!request.error ? !request.error : 'Sucess'} - `,
        }

        createLog(log_body)
        setLoading(false)
        handleStatePay({ request, code: !request.error ? 'confirm' : 'error' })
    }

    const goBack = () => {
        handleBack(false)
    }

    return (
        <Container>
            <Nav>
                <IconButton aria-label="go back" onClick={goBack}>
                    <ArrowBackRoundedIcon />
                </IconButton>
                ¡Finaliza la compra!
            </Nav>
            <Form>
                <HeaderWrapper>
                    <Alert>
                        <ErrorIcon />
                        <Text fontSize="14px">
                            Recuerda que tiene que terminar la compra para ser
                            parte de nuestro evento.
                        </Text>
                    </Alert>
                </HeaderWrapper>
                <CardWrapper>
                    <Body>
                        <Text fontWeight="600" fontSize="18px">
                            Tarjeta de crédito o débito
                        </Text>
                        <Card
                            number={state.number}
                            name={state.name}
                            expiry={state.expiry}
                            cvc={state.cvc}
                            focused={state.focused}
                            callback={handleCallback}
                            acceptedCards={['visa', 'mastercard']}
                            placeholders={{
                                name: 'Nombre completo',
                                expiry: 'vto',
                            }}
                        />
                    </Body>
                </CardWrapper>
                {
                    !!error && <ErrorCard />
                }
                <FormWrapper>
                    <Text fontWeight="600" fontSize="18px">
                        Datos de la tarjeta
                    </Text>
                    <div />
                    <TextField
                        type="tel"
                        name="number"
                        className="form-control"
                        error={state.issuer === 'unknown'}
                        helperText={
                            state.issuer === 'unknown' && `Tarjeta no válida`
                        }
                        placeholder="Número de tarjeta"
                        pattern="[\d| ]{16}"
                        inputProps={{ maxLength: 19 }}
                        size="small"
                        required
                        onChange={handleInputChange}
                        onFocus={handleInputFocus}
                    />
                    <TextField
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Nombre de titular"
                        required
                        size="small"
                        value={state?.name}
                        onChange={handleInputChange}
                        onFocus={handleInputFocus}
                    />
                    <TextField
                        type="tel"
                        name="expiry"
                        className="form-control"
                        error={!!expireV}
                        helperText={!!expireV && expireV}
                        placeholder="Fecha de vencimiento (MM/AA)"
                        pattern="\d\d/\d\d"
                        required
                        size="small"
                        onChange={handleInputChange}
                        onFocus={handleInputFocus}
                    />
                    <TextField
                        type="tel"
                        name="cvc"
                        className="form-control"
                        placeholder="CVC"
                        pattern="\d{3,4}"
                        required
                        size="small"
                        onChange={handleInputChange}
                        onFocus={handleInputFocus}
                    />
                </FormWrapper>
                <SubmitPay>
                    {!loading ? (
                        <Button
                            disabled={
                                !!state.number &&
                                state.number.length === 19 &&
                                !!state.cvc &&
                                state.cvc.length >= 3 &&
                                !!state.expiry &&
                                state.expiry.length === 5 &&
                                !!state.name &&
                                state.issuer !== 'unknown' &&
                                !expireV
                                    ? false
                                    : true
                            }
                            fullWidth
                            onClick={handleSubmit}
                            color="primary"
                            variant="contained"
                        >
                            Pagar
                        </Button>
                    ) : (
                        <Loading />
                    )}
                </SubmitPay>
            </Form>
        </Container>
    )
}

export default Pay

const Container = styled.div`
    padding: 24px;
    position: relative;
    @media (max-width: 768px) {
        background-color: white;
    }
`

const Nav = styled.div`
    color: #b31d15;
    font-size: 18px;
    font-weight: 600;
    position: absolute;
    display: flex;
    justify-content: flex-start;
    padding-left: 24px;
    align-items: center;
    height: 60px;
    width: 100%;
    left: 0;
    top: 0;
    box-shadow:
        rgba(24, 39, 75, 0.12) 0px 6px 12px -6px,
        rgba(24, 39, 75, 0.08) 0px 8px 24px -4px;
    @media (max-width: 768px) {
        width: calc(100% - 24px);
    }
`

const Form = styled.div`
    margin-top: 52px;
    height: calc(100% - 52px);
    display: flex;
    flex-direction: column;
    gap: 15px;
`

const CardWrapper = styled.div``

const FormWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    box-shadow:
        0px 8px 22px -6px rgba(24, 39, 75, 0.12),
        0px 14px 64px -4px rgba(24, 39, 75, 0.12);
    padding: 24px;
    border-radius: 20px;
`

const HeaderWrapper = styled.div`
    box-shadow:
        0px 8px 22px -6px rgba(24, 39, 75, 0.12),
        0px 14px 64px -4px rgba(24, 39, 75, 0.12);
    padding: 12px 24px;
    border-radius: 20px;
`

const Body = styled.div`
    /* width: 100%; */
    display: grid;
    gap: 12px;
    border-radius: 20px;
    /* background-color: red; */
    padding: 32px 24px;
    box-shadow:
        0px 8px 22px -6px rgba(24, 39, 75, 0.12),
        0px 14px 64px -4px rgba(24, 39, 75, 0.12);
`

const SubmitPay = styled.div`
    height: auto;
    border-radius: 20px;
    margin-top: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
`

const Alert = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
    color: #b31d15;
    svg {
        font-size: 20px;
    }
`
