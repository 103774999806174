import React from "react";
import styled from "styled-components";
import WarningAmberIcon from "@mui/icons-material/WarningRounded";
import { responseTuCuota } from "../../helpers/tucuota";

const ErrorCard = () => {

    let error = localStorage.getItem('error')
    error = !!error && JSON.parse(error)

    return (
        <Card>
            <WarningAmberIcon />
            <Col>
                <Error>ERROR {error?.status}</Error>
                <Message>
                    {error?.status === 502 && "No se pudo conectar con el servidor."}{" "}
                    {error?.status === 410 &&
                        `Se produjo un error con el método de pago. ${responseTuCuota(error)} `}
                    {error?.status === 411 &&
                        `No fue posible procesar el pago. ${responseTuCuota(error)} `}
                    <span>
                        {error?.status === 502
                            ? "Por favor inténtalo en unos minutos."
                            : "Por favor inténtalo nuevamente."}
                    </span>
                </Message>
            </Col>
        </Card>
    );
};

export default ErrorCard;

const Card = styled.div`
    display: flex;
    width: 100%;
    background-color: #f9f5ec;
    border-radius: 10px;
    border: solid 1px #c29f43;
    gap: 12px;
    padding: 1rem;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
    svg {
        color: #c29f43;
        font-size: 2.3rem;
    }
`;

const Col = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
`;
const Error = styled.div`
    font-weight: bold;
    font-size: 1rem;
`;
const Message = styled.div`
    font-size: 1.2rem;

    span {
        font-weight: bold;
        font-size: 1.2rem;
    }
`;
