export const responseTuCuota = (response) => {

    let res = !!response && JSON.parse(response?.data)

    function findFirstString(obj) {
        for (const key in obj) {
            if (key === 'message') continue; 
            if (typeof obj[key][0] === 'string') {
                return obj[key][0];
            } else if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
                const result = findFirstString(obj[key]);
                if (result) return result;
            }
        }
        return null;
    }

    const errorMessage = findFirstString(res);

    if (res.errors) {
        if (res.errors.payment_method_expiration_year) {
            return res.errors.payment_method_expiration_year
        }
        if (res.errors.payment_method_expiration_month) {
            return "El mes no debe ser mayor a 12."
        }
        if (res.errors.payment_method_number) {
            return "El número de tarjeta es inválido"
        }
        if (res.errors.amount) {
            return "¡El valor del producto debe ser al menos $1, contactar con soporte!"
        }
        if (!!errorMessage) {
            return errorMessage
        }
        else {
            return "¡Ocurrió un error inesperado al hacer la transacción!"
        }
    }
    else {
        return "¡Ocurrió un error inesperado al hacer la transacción!"
    }
}