import styled from '@emotion/styled'
import { Button } from '@mui/material'
import React, { useEffect } from 'react'
import Amount from './Amount'
import Participants from './Participants'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import Buyer from './Buyer'
import useValidateParticipants from '../../hooks/useValidateParticipants'
import useValidateBuyer from '../../hooks/useValidateBuyer'

const Form = (props) => {
    const {
        typeTicket,
        buyer,
        count,
        handleTickets,
        setParticipants,
        participants,
        tickets,
        handleTypeTicket,
        handleTogleMain,
        buyerForm,
        handleBuyer,
        ticket,
    } = props

    useEffect(() => {
        localStorage.setItem('buyer_form', JSON.stringify(buyerForm))
        localStorage.setItem('participants', JSON.stringify(participants))
    })

    return (
        <FormWrapper>
            <Nav>Selecciona tus entradas</Nav>
            <Content>
                <Amount
                    typeTicket={typeTicket}
                    tickets={tickets}
                    count={count}
                    handleTickets={handleTickets}
                    handleTypeTicket={handleTypeTicket}
                />
                {buyer && (
                    <Buyer buyerForm={buyerForm} handleBuyer={handleBuyer} />
                )}
                <Participants
                    buyerForm={buyerForm}
                    buyer={!!buyer}
                    participants={participants}
                    setParticipants={setParticipants}
                    count={count}
                    ticket={ticket}
                />
            </Content>
        </FormWrapper>
    )
}

export default Form

const FormWrapper = styled.div`
    padding: 24px;
    position: relative;
    @media (max-width: 768px) {
        background-color: white;
    }
`

const Nav = styled.div`
    color: #b31d15;
    font-size: 18px;
    font-weight: 600;
    position: absolute;
    display: flex;
    justify-content: flex-start;
    padding-left: 24px;
    align-items: center;
    height: 60px;
    width: 100%;
    left: 0;
    top: 0;
    box-shadow:
        rgba(24, 39, 75, 0.12) 0px 6px 12px -6px,
        rgba(24, 39, 75, 0.08) 0px 8px 24px -4px;
    @media (max-width: 768px) {
        width: calc(100% - 24px);
    }
`

const Content = styled.div`
    margin-top: 32px;
    /* position: relative; */
`

const ButtonFake = styled(Button)`
    /* position: fixed;
    top: 0;
    left: 0; */
    display: none;
    @media (max-width: 768px) {
        display: flex;
        margin-top: 12px;
    }
`
