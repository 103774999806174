import { useEffect, useState } from 'react'

const useValidateBuyer = (buyer) => {
    const [values, setValues] = useState(false)

    useEffect(() => {
        validateBuyer(buyer)
    }, [buyer])

    const validateBuyer = (buyer) => {
        if (buyer?.name && buyer?.email && buyer?.country && buyer?.phone) {
            setValues(false)
            return
        }
        setValues(true)
    }

    return { buyerState: values }
}

export default useValidateBuyer
