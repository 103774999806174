import styled from '@emotion/styled'
import { IconButton, Button } from '@mui/material'
import React from 'react'
// import logo from "../../assets/images/logo-aden.svg";
import logo from '../../assets/images/aden-summit-2024.png'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded'
import Text from '../commons/Text'

const NavBar = (props) => {
    const { handleTogleMain } = props

    const handleRedirectToLive = () => {
        window.open('https://live.aden.org/', '_self')
    }

    return (
        <Container>
            <img alt="Aden 30 años" src={logo} />
            <IconButton sx={{ display: 'none' }} onClick={handleTogleMain}>
                <InfoRoundedIcon />
            </IconButton>

            <AlreadyRegisteredWrapper>
                <Text fontWeight="700" color="#616161">
                    ¿Ya estás registrado?
                </Text>
                <GoToLiveButton
                    onClick={handleRedirectToLive}
                    variant="outlined"
                >
                    Ir a live
                </GoToLiveButton>
            </AlreadyRegisteredWrapper>
        </Container>
    )
}

export default NavBar

const Container = styled.div`
    position: relative;
    height: 70px;
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 10;
    box-shadow:
        rgba(24, 39, 75, 0.12) 0px 6px 12px -6px,
        rgba(24, 39, 75, 0.08) 0px 8px 24px -4px;
    img {
        height: 130px;
    }
    /* button {
        display: none;
    } */
    @media (max-width: 768px) {
        width: calc(100% - 30px);
        padding: 0 15px;
        justify-content: space-between;
        button {
            display: flex;
            color: #b3211d;
        }
    }
`

const AlreadyRegisteredWrapper = styled.div`
    position: absolute;
    margin: auto;
    right: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
`

const GoToLiveButton = styled(Button)``
