import styled from '@emotion/styled'
import React from 'react'

const Span = (props) => {

    return (
        <TextWrapper {...props}></TextWrapper>
    )
}

export default Span

const TextWrapper = styled.span`
    font-size: ${p => p.fontSize};
    font-weight: ${p => p.fontWeight};
    color: ${p => p.color};
`