import styled from '@emotion/styled'
import { Button, IconButton } from '@mui/material'
import React from 'react'
import Text from '../commons/Text'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'
import Span from '../commons/Span'
import useValidateParticipants from '../../hooks/useValidateParticipants'
import { getDate, getLocalUTC, getMonth } from '../../helpers/date'
import CloseIcon from '@mui/icons-material/Close'
import parse from 'html-react-parser'
import useValidateBuyer from '../../hooks/useValidateBuyer'
import EventIcon from '@mui/icons-material/Event'
import DiscountCode from './DiscountCode'
import moment from 'moment'
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded'

const EventCard = (props) => {
    const {
        eventId,
        count,
        participants,
        confirm = false,
        confirmBtn = true,
        handleSubmit,
        event,
        ticket,
        isOpen = false,
        handleTogleMain,
        buyer,
        isBuyer = false,
        handleCode,
        code = false,
        defaultCode,
    } = props
    
    const { state } = useValidateParticipants(participants, count)
    const { buyerState } = useValidateBuyer(buyer || null)

    const validateSubmit = () => {
        if (!!isBuyer) {
            return state && !buyerState ? false : true
        }
        return !state ? true : false
    }

    const handleDiscount = (value) => {
        handleCode(value)
    }

    /* const getDate = (date) => {
        const utc = getLocalUTC();
        return utc >= 0 ? moment(date).subtract(utc, "hours") : moment(date).add(utc, "hours");
    }; */

    return (
        <>
            <CardWrapper isOpen={isOpen} confirm={confirm}>
                <ImgWrapper confirm={confirm}>
                    <img alt="foto" src={event.image_url} />
                    <IconButton onClick={() => handleTogleMain(false)}>
                        <CloseIcon />
                    </IconButton>
                </ImgWrapper>
                <BodyContainer>
                    <Text fontSize="18px" fontWeight="700" color="#222222">
                        {event.publication_name}
                    </Text>
                    <Description>
                        <Text fontSize="16px" fontWeight="400" color="#A8A8A8">
                            {parse(event?.description || '')}
                        </Text>
                    </Description>
                </BodyContainer>
                {!confirm && (
                    <Footer>
                        <Divider />
                        {/* Codigo de descuento */}
                        {confirmBtn && ticket?.price > 0 && (
                            <DiscountCode
                                percenteje={code}
                                handleDiscount={handleDiscount}
                                eventId={eventId}
                                defaultCode={defaultCode}
                            />
                        )}
                        {count === 0 ? (
                            <Tickets>
                                <AddShoppingCartIcon />
                            </Tickets>
                        ) : (
                            <div>
                                <Text
                                    fontWeight="500"
                                    fontSize="12px"
                                    color="#777"
                                    margin="16px 0 0 0"
                                >
                                    RESUMEN DE COMPRA
                                </Text>

                                {code && !confirmBtn && (
                                    <AppliedDiscount>
                                        <div className="text">
                                            <p>
                                                ¡Código de descuento aplicado!
                                            </p>
                                            <h2>{code.code}</h2>
                                        </div>
                                    </AppliedDiscount>
                                )}
                                <TicketsUnits>
                                    <Info>
                                        <Span color="#999">{count}x</Span>
                                        <Span>{ticket?.name}</Span>
                                    </Info>
                                    {!code ? (
                                        <Text fontWeight="500">
                                            {ticket?.price === 0
                                                ? 'FREE'
                                                : `US$${ticket?.price}`}
                                            {/* {ticket?.price === 0 ? "FREE" : `US$${ticket?.price * count}`} */}
                                        </Text>
                                    ) : (
                                        <Mount>
                                            {ticket?.price !== 0 && (
                                                <Discount fontWeight="500">{`US$${ticket?.price}`}</Discount>
                                            )}
                                            <Text fontWeight="500">
                                                {ticket?.price === 0
                                                    ? 'FREE'
                                                    : `US$${
                                                          ticket?.price -
                                                          (ticket?.price *
                                                              (code?.percentage >
                                                              100
                                                                  ? 100
                                                                  : code?.percentage)) /
                                                              100
                                                      }`}
                                            </Text>
                                        </Mount>
                                    )}
                                </TicketsUnits>
                            </div>
                        )}
                        {confirmBtn && (
                            <Button
                                onClick={() => {
                                    handleTogleMain(false)
                                    handleSubmit()
                                }}
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={validateSubmit()}
                            >
                                {ticket?.price === 0 ||
                                ticket?.price -
                                    (ticket?.price *
                                        (code?.percentage > 100
                                            ? 100
                                            : code?.percentage)) /
                                        100 <=
                                    0
                                    ? 'Completar inscripción'
                                    : 'Continuar compra'}
                            </Button>
                        )}
                    </Footer>
                )}
            </CardWrapper>
            {!confirm && (
                <Preview isOpen={isOpen}>
                    {count === 0 ? (
                        <Tickets>
                            <AddShoppingCartIcon />
                        </Tickets>
                    ) : (
                        <div className="column">
                            <Text
                                fontWeight="500"
                                fontSize="12px"
                                color="#777"
                                margin="16px 0 0 0"
                            >
                                RESUMEN DE COMPRA
                            </Text>

                            {code && !confirmBtn && (
                                <AppliedDiscount>
                                    <div className="text">
                                        <p>¡Código de descuento aplicado!</p>
                                        <h2>{code.code}</h2>
                                    </div>
                                </AppliedDiscount>
                            )}
                            <TicketsUnits>
                                <Info>
                                    <Span color="#999">{count}x</Span>
                                    <Span>{ticket?.name}</Span>
                                </Info>
                                {!code ? (
                                    <Text fontWeight="500">
                                        {ticket?.price === 0
                                            ? 'FREE'
                                            : `US$${ticket?.price}`}
                                    </Text>
                                ) : (
                                    <Mount>
                                        {ticket?.price !== 0 && (
                                            <Discount fontWeight="500">{`US$${ticket?.price}`}</Discount>
                                        )}
                                        <Text fontWeight="500">
                                            {ticket?.price === 0
                                                ? 'FREE'
                                                : `US$${
                                                      ticket?.price -
                                                      (ticket?.price *
                                                          count *
                                                          (code?.percentage >
                                                          100
                                                              ? 100
                                                              : code?.percentage)) /
                                                          100
                                                  }`}
                                        </Text>
                                    </Mount>
                                )}
                            </TicketsUnits>
                        </div>
                    )}
                    {confirmBtn && (
                        <Button
                            onClick={() => {
                                handleTogleMain(false)
                                handleSubmit()
                            }}
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={validateSubmit()}
                        >
                            {ticket?.price === 0 ||
                            ticket?.price -
                                (ticket?.price *
                                    (code?.percentage > 100
                                        ? 100
                                        : code?.percentage)) /
                                    100 <=
                                0
                                ? 'Completar inscripción'
                                : 'Continuar compra'}
                        </Button>
                    )}
                    <SeeMoreButton onClick={() => handleTogleMain(true)}>
                        <ExpandLessRoundedIcon />
                    </SeeMoreButton>
                </Preview>
            )}
        </>
    )
}

export default EventCard

const CardWrapper = styled.div`
    border-radius: 20px;
    min-height: calc(100vh - 160px);
    height: 100%;
    box-shadow:
        0px 8px 22px -6px rgba(24, 39, 75, 0.12),
        0px 14px 64px -4px rgba(24, 39, 75, 0.12);
    ${(p) => !p.confirm && 'border-bottom-left-radius: 0px'};
    @media (max-width: 768px) {
        height: 100%;
        border-radius: 0px;
        position: fixed;
        width: 100%;
        background-color: white;
        bottom: 0;
        z-index: 20;
        transition: ease-in-out 0.5s all;
        /* Menu toggle */
        ${(p) =>
            !p.isOpen
                ? `
            bottom: -100%;
        `
                : `
            bottom: 0%;
        `}/* Cerrado */

        
        /* Abierto */
    }
`

const ImgWrapper = styled.div`
    height: 194px;
    position: relative;
    button {
        display: none;
        @media (max-width: 768px) {
            display: grid;
        }
    }
    img {
        width: 100%;
        height: 100%;
        border-top-right-radius: 20px;
        ${(p) => p.confirm && 'border-radius: 20px'};
        @media (max-width: 768px) {
            border-radius: 0px;
        }
    }
    button {
        position: absolute;
        top: 10px;
        right: 10px;
        background-color: white;
        color: #b3211d;
    }
`

const BodyContainer = styled.div`
    padding: 24px;
    display: flex;
    flex-direction: column;
    /* height: calc(70% - 260px); */
    gap: 16px;
`

const Divider = styled.div`
    border: 1px solid #f1f1f1;
    margin-bottom: 0.6rem;
`

const Footer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-grow: 1;
    flex: 1;
    padding: 0 24px;
`

const Tickets = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        color: rgba(0, 0, 0, 0.12);
        font-size: 48px;
    }
`

const TicketsUnits = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
`

const Info = styled.div`
    display: flex;
    gap: 8px;
`

const Description = styled.div`
    max-height: 140px;
    max-width: 100%;
    overflow-y: none;
`

const TextCenter = styled(Text)`
    display: flex;
    height: 30px;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
    svg {
        font-size: 20px;
    }
`

const Discount = styled.div`
    text-decoration: line-through;
    /* font-weight: 600; */
    font-size: 12px;
    /* gap: -5px; */
    color: #888;
`

const Mount = styled.div`
    display: flex;
    flex-direction: column;
`

const AppliedDiscount = styled.div`
    display: flex;
    align-items: center;
    padding: 1rem;
    border-radius: 10px;
    border: 1px solid #f1f1f1;
    background-color: #fef6f6;
    margin: 10px 0;

    .text {
        display: flex;
        flex-direction: column;
        gap: 0.1rem;

        p {
            color: #b31d15;
            font-size: 0.8rem;
            margin-bottom: 0;
            margin-top: 0;
        }

        h2 {
            font-size: 1.2rem;
            color: #222222;
            font-weight: 900;
            text-transform: uppercase;
            margin-bottom: 0;
            margin-top: 0;
        }
    }
`

const Preview = styled.div`
    box-sizing: border-box;
    display: none;

    @media (max-width: 768px) {
        ${(p) =>
            !p.isOpen
                ? `
            display: flex; flex-direction:column;
        `
                : `
            display: none; 
        `}
        align-items: center;
        gap: 1rem;
        padding: 2rem;
        width: 100vw;
        position: sticky;
        bottom: 0;
        background-color: #ffffff;
        z-index: 999999999999999999999999999999999;
        .column {
            width: 100%;
            display: flex;
            flex-direction: column;
        }
        box-shadow:
            0px 8px 28px -6px rgba(24, 39, 75, 0.12),
            0px 18px 88px -4px rgba(24, 39, 75, 0.14);
    }
`

const SeeMoreButton = styled(IconButton)`
    position: absolute;
    top: 0.5rem;
`
