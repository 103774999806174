import styled from '@emotion/styled'
import { Button, IconButton, Snackbar, TextField } from '@mui/material'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import React, { useEffect, useState } from 'react'
import { checkCode } from '../../api/event'
import { createLog } from '../../api/history'
import Text from '../commons/Text'

const DiscountCode = (props) => {
    const { eventId, handleDiscount, percenteje = null, defaultCode } = props

    const [defCode, setDefCode] = useState(defaultCode)
    const [code, setCode] = useState('')
    const [appliedDct, setAppliedDct] = useState(false)
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState(null)
    // const [percentage, setPercentage] = useState(null)

    const [open, setOpen] = useState(false)

    useEffect(() => {
        if (defCode && defCode !== '') {
            setCode(defCode)
            handleSubmit()
        }
    }, [code, defCode])

    const handleChange = (e) => {
        setCode(e.target.value?.toUpperCase())
    }

    const handleClick = () => {
        setOpen(true)
    }

    const handleClose = (_, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setOpen(false)
    }

    const handleSubmit = async () => {
        const body = {
            event_id: parseInt(eventId, 10),
            code: code,
        }

        const log_body = {
            name: 'false',
            dni: 'false',
            phone: 'false',
            email: 'false',
            url: 'false',
            discount_code: code,
            action: `Se ingresó código de descuento`,
        }
        setLoading(true)

        const logRequest = await createLog(log_body)
        if (!logRequest.error) {
            console.log('logRequest.error', logRequest.error)
        }

        const request = await checkCode(body)
        setLoading(false)
        if (request.error) {
            setMessage(request.error.response.data?.error)
            setAppliedDct(false)
        } else {
            if (!request?.response_data) {
                setMessage('Código no válido')
                setAppliedDct(false)
            } else {
                handleClick()
                handleDiscount({ code, percentage: request.percentage })
                setAppliedDct(true)
            }
        }
    }

    const resetCode = () => {
        setLoading(true)
        setCode('')
        setDefCode('')
        handleDiscount(null)
        setMessage(null)
        setAppliedDct(false)
        setLoading(false)
    }

    return (
        <div>
            {!appliedDct && (
                <>
                    <Text fontWeight="500" fontSize="12px" color="#777">
                        ¿TIENES CÓDIGO DE DESCUENTO?
                    </Text>
                    <CodeWrapper>
                        <TextField
                            disabled={appliedDct}
                            error={message}
                            label={null}
                            inputProps={{}}
                            value={code || percenteje?.code}
                            helperText={message ? message : null}
                            onChange={handleChange}
                            size="small"
                            variant="outlined"
                        />
                        {!loading ? (
                            <Button
                                onClick={handleSubmit}
                                disabled={code === ''}
                                variant="outlined"
                            >
                                Aplicar
                            </Button>
                        ) : (
                            <Button disabled={true} variant="outlined">
                                Aplicar
                            </Button>
                        )}
                    </CodeWrapper>
                </>
            )}
            {appliedDct && !loading && (
                <AppliedDiscount>
                    <div className="text">
                        <p>¡Código de descuento aplicado!</p>
                        <h2>{code}</h2>
                    </div>
                    {/* <IconButton aria-label='delete' onClick={resetCode}>
                        <DeleteRoundedIcon />
                    </IconButton> */}
                </AppliedDiscount>
            )}

            <SnackbarFake
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
                message={`Código del ${percenteje?.percentage}% descuento aplicado`}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                severity="success"
            />
        </div>
    )
}

export default DiscountCode

const CodeWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 3.5fr 1fr;
    gap: 8px;
    margin-top: 8px;
`

const SnackbarFake = styled(Snackbar)`
    font-weight: bold;
    .MuiPaper-root {
        background-color: #4d9a51;
    }
    .MuiSnackbarContent-root {
        background-color: #4d9a51;
    }
`

const AppliedDiscount = styled.div`
    display: grid;
    grid-template-columns: 1fr 48px;
    align-items: center;
    padding: 1rem;
    border-radius: 10px;
    border: 1px solid #f1f1f1;
    background-color: #fef6f6;
    margin: 10px 0;

    .text {
        display: flex;
        flex-direction: column;
        gap: 0.1rem;

        p {
            color: #b31d15;
            font-size: 0.8rem;
            margin-bottom: 0;
            margin-top: 0;
        }

        h2 {
            font-size: 1.2rem;
            color: #222222;
            font-weight: 900;
            text-transform: uppercase;
            margin-bottom: 0;
            margin-top: 0;
        }
    }
`
