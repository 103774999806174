import axios from "axios"

const URL = process.env.REACT_APP_SISAPI

export const processPay = async body => {
    try {
        const req = await axios.post(`${URL}/v2/acropolis/events/process_event_payment`, body)
        return req.data
    } catch (error) {
        return { error }
    }
}