import axios from 'axios'

export const createLog = async (body) => {
    const URL = `${process.env.REACT_APP_REPO_V1}/ecommerce/history/`

    try {
        const request = await axios.post(URL, body)
        return request
    } catch (error) {
        console.log('error', error)
    }
}
