import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import MaterialThemeProvider from './theme/MaterialProvider';
import setupAxios from './config/setupAxios';
import axios from 'axios';
import { BrowserRouter } from "react-router-dom";

setupAxios(axios, process.env.REACT_APP_APIKEY);

// Limpio los logs
(process.env.REACT_APP_NAME === 'PRODUCCION') && (console.log = console.warn = console.error = () => { });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MaterialThemeProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </MaterialThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
